import React from 'react'
import { Grid, ThemeUICSSObject } from 'theme-ui'
import ProductCard from '~/components/Product/Card'
import type {
  ICatalogProduct,
  ICatalogBundle,
  IProduct,
  IBundle,
  IRelatedProduct,
} from 'graphql-cms/types'
import { ISales } from 'basta-ai/types'
import styles from './styles.module.css'

interface Props {
  products: (ICatalogProduct | ICatalogBundle | IProduct | IBundle | IRelatedProduct)[]
  sales?: ISales[]
  viewType?: string
  columns?: Array<number> | number
  customStyle?: ThemeUICSSObject
  gap?: string
  maxWidth?: ThemeUICSSObject
  maxHeight?: string
}

const ProductList: React.FC<Props> = ({
  products,
  sales,
  viewType,
  columns = [1, 2, 3],
  customStyle ={},
  maxWidth = {},
  maxHeight = '550px',
}) => (
  <Grid
    className={styles.flexContainer}
    columns={columns}
    sx={{
      gap: customStyle.gap,
    }}
  >
    {products.map((product) => {
      if (product.__typename === 'Product') {
        return (
          <ProductCard
            key={product.slug}
            product={product}
            viewType={viewType}
            sales={sales}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
          />
        )
      }
      return null
    })}
  </Grid>
)

export default ProductList
