/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, Container, Flex, Heading } from 'theme-ui'
import ResponsiveImage from '~/components/Generic/Image'
import type { IHero } from 'graphql-cms/types'
import styles from './styles.module.css'

export type ImageHeroProps = Pick<IHero, 'heading' | 'subheading' | 'image' | 'imageMobile'>

const ImageHeroDesktop: React.FC<
  ImageHeroProps & {
    type?: string
    cta
  }
> = ({ type = 'other', cta, heading, image, subheading }) => {
  return (
    <Container
      datatype='image-hero-desktop'
      variant="fullWidth"
      sx={{
        display: [
          type !== 'hero' ? 'none' : 'block',
          type !== 'hero' ? 'none' : 'block',
          'block',
        ],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            maxHeight: '100%',
          }}
        >
          <ResponsiveImage image={image} quality={90} />
        </Box>
        <Flex
          sx={{
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'end',
            padding: ['2rem 1rem', '1.8rem'],
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          {heading && (
            <Heading className={styles.heading} as="h1">
              {heading}
            </Heading>
          )}
          {subheading && (
            <Heading className={styles.subHeading} as="h3">
              {subheading}
            </Heading>
          )}
          {cta && <span className={styles.callToAction}>{cta}</span>}
        </Flex>
      </Flex>
    </Container>
  )
}

const ImageHeroMobile: React.FC<ImageHeroProps & {
  cta
}> = ({
  cta,
  heading,
  image,
  imageMobile,
  subheading,
}) => {
  return (
    <Container
      datatype='image-hero-mobile'
      variant="fullWidth"
      sx={{
        display: ['block', 'block', 'none'],
      }}
    >
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
        }}
      >
        <Box
          datatype='image-container'
          sx={{
            width: '100%',
            height: '100%',
            maxHeight: '100%',
          }}
        >
          <ResponsiveImage image={imageMobile ? imageMobile : image} quality={90} />
        </Box>
        <Flex
          datatype='text-container'
          sx={{
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          {heading && (
            <Heading
              className={styles.heading}
              as="h1"
              sx={{
                paddingTop: '40px !important',
              }}
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Heading
              className={styles.subHeading}
              as="h3"
              sx={{
                paddingTop: '20px !important',
              }}
            >
              {subheading}
            </Heading>
          )}
          {cta && <span className={styles.callToAction}>{cta}</span>}
        </Flex>
      </Box>
    </Container>
  )
}

export const ImageHero: React.FC<
  ImageHeroProps & {
    type
    cta
  }
> = ({ type, cta, ...props }) => {
  if (type === 'hero') {
    return <ImageHeroDesktop type={type} {...props} cta={cta} />
  }

  return (
    <>
      <ImageHeroDesktop {...props} cta={cta} />
      <ImageHeroMobile {...props} cta={cta} />
    </>
  )
}

export default ImageHero
