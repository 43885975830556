import React from 'react'
import ImageHero, { ImageHeroProps } from '~/components/Generic/ImageHero'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const ContentHero: React.FC<ImageHeroProps & {
  type?: string
  cta?
}> = ({
  type = 'other',
  cta,
  heading,
  subheading,
  image,
  imageMobile,
}) => {

  const render_options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <span>{children}</span>,
    },
  }

  const ctaHtml = cta ? documentToReactComponents(cta.json, render_options) : ''

  return (
    <ImageHero
      type={type}
      cta={ctaHtml}
      heading={heading}
      subheading={subheading}
      image={image}
      imageMobile={imageMobile}
    />
  )
}

export default ContentHero
