/* eslint-disable @typescript-eslint/no-explicit-any */
import Slider, { Settings } from 'react-slick'
import { ThemeUICSSObject } from 'theme-ui'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface Props {
  sliderRef: any
  children: JSX.Element | JSX.Element[]
  customProps?: Settings
  containerSx?: ThemeUICSSObject
}

const SlickSlider: React.FC<Props> = ({
  sliderRef,
  children,
  customProps,
  containerSx,
}) => {
  return (
    <Slider
      ref={sliderRef}
      className="center"
      {...{
        dots: false,
        arrows: false,
        ...customProps,
      }}
      sx={{
        ...containerSx,
      }}
    >
      {children}
    </Slider>
  )
}

export default SlickSlider
