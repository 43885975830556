/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource theme-ui */
import React from 'react'
import { Box, Flex, Heading, ThemeUICSSObject } from 'theme-ui'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import RichText from '~/components/Generic/RichText'

interface Props {
  children?: React.ReactNode
  title: React.ReactNode
  description: string
  richDescription?: {
    json: string
  }
}

const markdownStyles = {
  h1: {
    fontFamily: 'Graphik Wide',
    fontSize: ['25px', '40px'],
    lineHeight: 'normal',
  },
  h2: {
    fontFamily: 'Graphik Wide',
    fontSize: '20px',
    lineHeight: 'normal',
    textAlign: 'left',
    marginTop: '40px',
  },
  h3: {
    fontFamily: 'Graphik Wide',
    fontSize: '17px',
    lineHeight: 'normal',
    textAlign: 'left',
    marginTop: '30px',
  },
  'blockquote, blockquote > p': {
    fontFamily: 'Mint Grotesk Display Light',
    fontSize: '12px',
    fontStyle: 'italic',
    lineHeight: 'normal',
    textAlign: 'left',
    '& > p': {
      margin: 0,
    },
  },
  p: {
    maxWidth: 'unset',
    margin: 0,
    marginBottom: '20px',
    fontFamily: 'Mint Grotesk Display Light',
    fontSize: ['15px', '17px'],
    lineHeight: 'normal',
    textAlign: 'left',
  },
  li: {
    margin: 0,
    marginBottom: '10px',
    fontFamily: 'Mint Grotesk Display Light',
    fontSize: ['15px', '17px'],
    lineHeight: 'normal',
    textAlign: 'left',
  },
  a: {
    fontFamily: 'Mint Grotesk Display',
    fontSize: ['15px', '17px'],
    lineHeight: 'normal',
    textTransform: 'none',
    letterSpacing: 'unset',
  },
} as ThemeUICSSObject


const TextSection: React.FC<Props> = ({ title, description, richDescription, children }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['100%', '90%'],
        margin: 'auto',
        marginTop: ['24px', '24px', '96px'],
        marginBottom: ['24px', '24px', '96px'],
        '& img': {
          maxWidth: '100%',
        },
        '[data-section="richtext-container"]':{
          'ol > li > p > b' :{
            fontFamily: 'Graphik Wide',
            fontSize: '20px',
            lineHeight: 'normal',
            textAlign: 'left',
            marginTop: '40px',
            display: 'block',
            marginBottom: '17px',
          },
          'ol > li > p > b ~ b' :{
            fontSize: '17px',
            fontFamily: 'Mint Grotesk Display',
            display: 'inline-block'
          },
          'ol ol b':{
            fontSize: '17px !important',
          },
          'p': {
            maxWidth: 'unset',
            margin: 0,
            marginBottom: '20px',
            fontFamily: 'Mint Grotesk Display Light',
            fontSize: ['15px', '17px'],
            lineHeight: 'normal',
            textAlign: 'left',
            "b":{
              fontFamily: 'Mint Grotesk Display',
            }
          },
        },
      }}
    >
      {title && (
        <Heading
          as="h2"
          sx={{
            fontFamily: 'Graphik Wide',
            textAlign: 'center',
            color: '#141414',
            variant: ['text.h3', 'text.h3', 'text.h2'],
            marginTop: ['16px', '16px', '0.67em'],
            marginBottom: ['16px', null, '0.67em'],
            fontSize: ['25px', '40px'],
            lineHeight: 'normal',
          }}
        >
          {title}
        </Heading>
      )}
      {description && (
        <Box
          sx={{
            textAlign: 'center',
            '& p': {
              maxWidth: ['100%', null, '70%'],
              margin: 'auto',
              marginBottom: ['24px', null, '48px'],
            },
            a: {
              variant: 'text.link',
            },
          }}
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw as any]} sx={markdownStyles}>
            {description}
          </ReactMarkdown>
        </Box>
      )}
      {richDescription && (
          <RichText
            RichText={richDescription}
          />
        )}
      {children}
    </Flex>
  )
}

export default TextSection
