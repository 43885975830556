import { useMemo, useRef, useState } from 'react'
import { Box, Container, Flex, Grid, Heading, Input } from 'theme-ui'
import { useTranslate } from '~/hooks'

import { IArchivesCollection } from 'graphql-cms/fragments/page'
import { ISlimArchive } from 'graphql-cms/fragments/archive'
import ArchiveItem from '~/components/Archive/Item'
import ArchiveMedia from '~/components/Archive/Media'
import SearchIcon from '~/assets/images/icons/search.svg'

type Toggle = {
  slug: string
  Thumbnail: () => React.ReactNode | undefined
}

const ArchivesCollection: React.FC<IArchivesCollection> = ({
  enableSearch,
  archivesCollection,
}) => {
  const translate = useTranslate()

  const globalArchives = archivesCollection.items
  const [archives, setArchives] = useState<ISlimArchive[]>(globalArchives)

  const searchRef = useRef<HTMLInputElement | null>(null)
  const handleSearch = () => {
    if (!searchRef.current) {
      setArchives(globalArchives)
      setToggle({
        slug: archives[0].slug,
        Thumbnail: () => (
          <ArchiveMedia
            image={archives[0].image}
            imageMobile={archives[0].imageMobile}
          />
        ),
      })
      return
    }

    const value = searchRef.current.value.toLocaleLowerCase()
    const filteredArchives = globalArchives.filter((item) => {
      const downTitle = item.title.toLocaleLowerCase()
      return downTitle.includes(value)
    })

    filteredArchives.length > 0
      ? setArchives(filteredArchives)
      : setArchives(globalArchives)

    setToggle({
      slug: archives[0].slug,
      Thumbnail: () => (
        <ArchiveMedia
          image={archives[0].image}
          imageMobile={archives[0].imageMobile}
        />
      ),
    })
  }

  const [toggle, setToggle] = useState<Toggle>({
    slug: archives[0].slug,
    Thumbnail: () => (
      <ArchiveMedia
        image={archives[0].image}
        imageMobile={archives[0].imageMobile}
      />
    ),
  })

  const renderArchives = useMemo(() => {
    return archives.map((item) => (
      <ArchiveItem
        key={item.slug}
        {...item}
        isActive={toggle.slug === item.slug}
        onToggle={() =>
          setToggle({
            slug: item.slug,
            Thumbnail: () => (
              <ArchiveMedia image={item.image} imageMobile={item.imageMobile} />
            ),
          })
        }
      />
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archives, toggle])

  return (
    <Container
      sx={{
        flexDirection: 'column',
        padding: '20px 0px 30px !important',
        overflow: 'visible',
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          gap: '0px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {enableSearch && (
          <Box
            as="div"
            sx={{
              position: ['unset', null, 'absolute'],
              top: ['inherit', null, '50%'],
              display: 'flex',
              alignItems: 'center',
              transform: ['unset', null, 'translateY(-50%)'],
              right: ['inherit', null, 0],
              padding: 0,
              marginBottom: ['40px', null, 0],
            }}
          >
            <SearchIcon style={{ width: '14px', height: '14px' }} />
            <Input
              ref={searchRef}
              onChange={handleSearch}
              placeholder={translate('pages.archive.search')}
              sx={{
                width: '75px',
                padding: '0 10px',
                border: 'none',
                fontFamily: 'Mint Grotesk Display',
                fontSize: '14px',
                lineHeight: 'normal',
                transition: '200ms ease-in-out',
                outline: 'none',
                '&:focus': {
                  width: '175px',
                },
                '&::placeholder': {
                  color: 'var(--black-one-off)',
                },
              }}
            />
          </Box>
        )}

        <Heading
          as="h2"
          sx={{
            fontFamily: 'Graphik Wide',
            fontSize: ['25px', null, '20px'],
            lineHeight: ['30px', null, 'normal'],
            textTransform: 'uppercase',
          }}
        >
          {translate('pages.archive.title')}
        </Heading>
      </Flex>

      <Grid
        sx={{
          gridTemplateColumns: ['1fr', null, '2.2fr 3fr'],
          gap: [0, null, '7%'],
          paddingTop: ['25px', null, 0],
        }}
      >
        <Box
          sx={{
            alignSelf: 'start',
            display: ['none', null, 'block'],
            position: ['unset', null, 'sticky'],
            top: 40,
            zIndex: 1,
          }}
        >
          {<toggle.Thumbnail />}
        </Box>

        <Box>{renderArchives}</Box>
      </Grid>
    </Container>
  )
}

export default ArchivesCollection
