/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Marquee from 'react-fast-marquee'
import { calculateTimeLeftScrollingText } from '~/utils'
import { IAnnouncementsCollection } from 'graphql-cms/types'
import { ThemeUICSSObject } from 'theme-ui'

type ScrollingBarINP = {
  speed?: number
  announcementsCollection?: IAnnouncementsCollection[] | []
  customText?: React.JSX.Element[] | []
  themeMode?: 'light' | 'dark'
  customSx?: ThemeUICSSObject
}

const ScrollingBar: React.FC<ScrollingBarINP> = ({
  speed = 50,
  announcementsCollection = [],
  customText = [],
  themeMode = 'light',
  customSx = {},
}) => {
  const [intervalScrolling, setIntervalScrolling] = useState(null)
  const [marqueeText, setMarqueeText] = useState<React.JSX.Element[] | []>([])

  const initFunctionality = () => {
    let marqueeTextFormated
    if (customText.length === 0) {
      marqueeTextFormated = announcementsCollection.map((annoucement, ind) => {
        return (
          <span key={ind}>
            OneOff______{annoucement.message}
            <span>
              EXPIRING IN [
              <span>
                {calculateTimeLeftScrollingText(new Date(annoucement.endDate))}
              </span>
              ]
            </span>{' '}
          </span>
        )
      })
    } else {
      marqueeTextFormated = customText
    }

    setMarqueeText(marqueeTextFormated)
  }

  useEffect(() => {
    if (!intervalScrolling) {
      const intervalScrolling = setInterval(() => {
        initFunctionality()
      }, 1000)

      setIntervalScrolling(intervalScrolling)
    }
  })

  if ((marqueeText?.length || 0) <= 0) {
    return <></>
  }

  return (
    <div className={styles.scrolling_container} sx={customSx}>
      <div
        className={[
          styles.scrolling_div,
          themeMode === 'dark' && styles.scrolling_div_dark_mode,
        ].join(' ')}
      >
        <Marquee speed={speed} autoFill={true}>
          <p>{marqueeText}</p>
        </Marquee>
      </div>
    </div>
  )
}

export default ScrollingBar
