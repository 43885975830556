import { IImage } from 'graphql-cms/types'
import { useEffect, useRef, useState } from 'react'
import { Flex } from 'theme-ui'
import ResponsiveImage from '~/components/Generic/Image'
import SlickSlider from '~/components/Generic/SlickSlider'
import { isMobileDevice } from '~/utils'

export interface MediaSlide {
  sys: {
    id: string
  }
  title: string
  media: IImage
}

interface Props {
  autoplaySpeed: number
  slides: MediaSlide[]
}

const MediaSlider: React.FC<Props> = ({ autoplaySpeed = 0, slides }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = async () => {
      const mobile = (await isMobileDevice()) || window.innerWidth < 832
      setIsMobile(mobile)
    }
    checkMobile()
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any | null>(null)
  const speed = autoplaySpeed * 1000

  const mediaVerticalPosition = () => {
    const positions = ['top', 'center', 'bottom']
    const random = Math.floor(Math.random() * positions.length)
    return positions[random]
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        padding: ['30px 0px', '30px 0px', '60px 0px'],
        '& .slick-list': {
          maxHeight: '700px',
        },
      }}
    >
      <SlickSlider
        sliderRef={sliderRef}
        customProps={{
          infinite: true,
          slidesToShow: isMobile ? 1 : 3,
          slidesToScroll: 1,
          initialSlide: isMobile ? 0 : 1,
          arrows: false,
          autoplay: Boolean(autoplaySpeed),
          autoplaySpeed: speed,
          centerMode: true,
          centerPadding: isMobile ? '0px' : '10px',
          variableWidth: true,
          adaptiveHeight: true,
          speed: 800, // Animation transition
        }}
      >
        {slides.map((slide) => (
          <ResponsiveImage
            key={slide.sys.id}
            image={slide.media}
            containerProps={{
              sx: {
                maxHeight: '640px',
                minHeight: ['350px', '460px', '640px'],
                width: `${slide.media.width}px`,
                position: 'relative',
                overflow: 'hidden',
                margin: isMobile ? '0px' : '0px 10px',
                img: {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  maxHeight: '90%',
                  objectFit: 'contain',
                  objectPosition: mediaVerticalPosition(),
                },
              },
            }}
          />
        ))}
      </SlickSlider>
    </Flex>
  )
}

export default MediaSlider
