/** @jsxImportSource theme-ui */
import React from 'react'
import { useAnalytics } from '@chordcommerce/react-autonomy'
import Link, { LinkProps } from '~/components/Generic/Link'

type Props = LinkProps & {
  title: string
  slug: string
}

/**
 * Link to a collection with Chord eventing wired up.
 */
const CollectionLink: React.FC<Props> = ({
  title,
  slug,
  children,
  ...props
}) => {
  const { trackCollectionClicked } = useAnalytics()

  const url = `/shop/?collection=${slug}`

  return (
    <Link
      href={url}
      onClick={() =>
        trackCollectionClicked({
          collection: {
            collectionId: slug,
            title,
            url: window.location.origin + url,
          },
        })
      }
      {...props}
    >
      {children}
    </Link>
  )
}

export default CollectionLink
