import { useEffect, useState } from 'react'
import { Box, ThemeUICSSObject } from 'theme-ui'

import { isMobileDevice } from '~/utils'
import { IImage } from 'graphql-cms/types'
import ResponsiveImage from '~/components/Generic/Image'

interface ArchiveMediaProps {
  image: IImage
  imageMobile?: IImage
  containerSx?: ThemeUICSSObject
}

const ArchiveMedia: React.FC<ArchiveMediaProps> = ({
  image,
  imageMobile,
  containerSx,
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mobile = isMobileDevice() || Boolean(window.innerWidth < 832)
    setIsMobile(mobile)
  }, [])

  return (
    <Box
      sx={{
        padding: 0,
        margin: 0,
        overflow: 'hidden',
        ...containerSx,
      }}
    >
      <ResponsiveImage
        image={image}
        containerProps={{
          sx: {
            display:
              isMobile && imageMobile ? ['none', null, 'block'] : 'block ',
          },
        }}
      />

      {isMobile && imageMobile && (
        <ResponsiveImage
          image={imageMobile}
          containerProps={{
            sx: {
              display: ['block', null, 'none'],
              position: 'relative',
              paddingBottom: '40%',
              '& > img': {
                position: 'absolute',
                top: 0,
                left: 0,
              },
            },
          }}
        />
      )}
    </Box>
  )
}

export default ArchiveMedia
